form {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 300px);
}

select {
  margin-bottom: 24px;
  height: 30px;
}

.full {
  height: 100vh;
}

.radio {
  display: flex;
  margin-bottom: 16px;
}

.radio label {
  margin-left: 12px;
}

.radio:last-of-type {
  margin-bottom: 24px;
}

button {
  height: 40px;
}